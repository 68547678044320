<template>
  <!-- ========== END HEADER ========== -->

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content">
    <div class="w-full max-w-2xl mx-auto pt-10 md:pt-16 px-4 sm:px-6 lg:px-8">
      <!-- Profile -->
      <div class="flex items-center gap-x-3">
        <div class="shrink-0">
          <img
            class="shrink-0 size-16 rounded-full"
            src="https://media.licdn.com/dms/image/v2/D4E03AQFE4b4Jn2bpIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685535258736?e=1729728000&v=beta&t=fR7_iXZaml3lkCmN3tBqSg3qh6EwBAAs2xnVIK2oLsk"
            alt="Avatar"
          />
        </div>

        <div class="grow">
          <h1
            class="text-xl font-semibold text-black-800 text-left dark:text-neutral-200"
          >
            Naël Benhiba
          </h1>
          <p class="text-sm text-gray-600 text-left dark:text-neutral-400">
            Développeur Full Stack et Étudiant en informatique
          </p>
        </div>
      </div>
      <!-- End Profile -->

      <!-- About -->
      <div class="mt-8">
        <p class="text-sm text-gray-600 text-left dark:text-neutral-400">
          Je m'appelle Naël et je suis actuellement étudiant en Bachelor
          Universitaire de Technologie (BUT) informatique à l'IUT Lyon 1 et en
          altenance chez <strong>Gonexa</strong>. Ayant déjà eu des affinités
          avec l'informatique depuis mes 13 ans, la filière STI2D m'a permis
          d'approfondir ma passion pour l'informatique.
        </p>

        <p class="mt-3 text-sm text-gray-600 text-left dark:text-neutral-400">
          J'ai donc décidé de poursuivre mes études dans ce domaine afin d'en
          faire mon métier. Au cours de ma formation, j'ai acquis des
          compétences en programmation (C, Java...), en développement web (HTML,
          CSS, JavaScript), en bases de données (SQL et ORACLE) et en gestion de
          projet. J'ai également eu l'occasion de mettre en pratique ces
          compétences lors de projets de groupe et dans l'entreprise qui
          m'accueille durant mon alternance.
        </p>

        <ul class="mt-5 flex flex-col gap-y-3">
          <li class="flex items-center gap-x-2.5">
            <Linkedin size="14" strokeWidth="2"></Linkedin>
            <a
              class="text-[13px] text-green-500 underline hover:text-green-800 hover:decoration-2 focus:outline-none focus:decoration-2 dark:text-neutral-500 dark:hover:text-neutral-400"
              href="https://www.linkedin.com/in/na%C3%ABl-benhiba-7a920a222/"
            >
              Naël Benhiba
            </a>
          </li>
        </ul>
      </div>
      <!-- End About -->

      <div class="mt-10 mb-5">
        <h2 class="mb-5 text-xl font-semibold text-left text-green-700">
          Mes projets
        </h2>
        <p class="mb-1 text-s font-medium text-left w-full">Filtres</p>
        <div class="flex flex gap-x-1 gap-y-1 flex-wrap">
          <div
            class="flex hs-tooltip [--trigger:hover] inline-block"
            v-for="(skill, index) in getSkills()"
            :key="index"
          >
            <button
              @click="toggleSkill(skill)"
              :class="{
                [revBackgroundClasses[skill.color]]: isSkillSelected(skill),
                [revColorClasses[skill.color]]: isSkillSelected(skill),
                [backgroundClasses[skill.color]]: !isSkillSelected(skill),
                [colorClasses[skill.color]]: !isSkillSelected(skill),
              }"
              class="hs-tooltip-toggle w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >
              {{ skill.title }}
            </button>
          </div>
        </div>
        <div class="w-full flex flex-row overflow-auto py-5">
          <div
            v-for="(project, index) in getFilteredProjects()"
            :key="index"
            class="flex flex-col mr-5 max-w-72 min-w-72 bg-white border shadow-sm rounded-xl p-4 md:p-5 dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70"
          >
            <h3
              class="text-lg text-left font-bold text-gray-800 dark:text-white"
            >
              {{ project.title }}
            </h3>
            <div class="mt-2 flex gap-x-1 gap-y-1 flex-wrap">
              <div
                class="w-fit h-fit"
                v-for="(skill, index) in project.skills"
                :key="index"
              >
                <span
                  v-if="skill != undefined"
                  :class="[
                    backgroundClasses[skill.color],
                    colorClasses[skill.color],
                  ]"
                  class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
                  >{{ skill.title }}</span
                >
              </div>
            </div>
            <p class="mt-2 text-left text-gray-500 dark:text-neutral-400">
              {{ project.desc }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-10 mb-5">
        <h2 class="mb-5 text-xl font-semibold text-left text-green-700">
          Les compétences
        </h2>
        <div
          class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-700 dark:after:border-neutral-700"
        >
          Compétences de la première année de BUT
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('BUT1').color],
              colorClasses[getSkill('BUT1').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("BUT1").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Première année de BUT Informatique
          </span>
        </div>
        <h2 class="mb-2 text-sm font-semibold text-left">
          Les description suivante concerne la première année de BUT
          Informatique
        </h2>

        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Réaliser').color],
              colorClasses[getSkill('Réaliser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Réaliser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Développer des applications Informatiques simples
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Optimiser').color],
              colorClasses[getSkill('Optimiser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Optimiser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Appréhender et construire des algorithmes
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Administrer').color],
              colorClasses[getSkill('Administrer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Administrer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Installer et configurer un poste de travail
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Gérer').color],
              colorClasses[getSkill('Gérer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Gérer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Concevoir et mettre en place une base de données à partir d'un
            cahier des charges clients
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Conduire').color],
              colorClasses[getSkill('Conduire').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Conduire").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Identifier les besoins métiers des clients et des utilisateurs
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Collaborer').color],
              colorClasses[getSkill('Collaborer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Collaborer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Identifier ses aptitudes pour travailler dans une équipe
          </span>
        </div>
        <!--FIN BUT 1-->
        <div
          class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-700 dark:after:border-neutral-700"
        >
          Compétences de la deuxième année de BUT
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('BUT2').color],
              colorClasses[getSkill('BUT2').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("BUT2").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Deuxième année de BUT Informatique
          </span>
        </div>

        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Réaliser').color],
              colorClasses[getSkill('Réaliser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Réaliser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Partir des exigences et aller jusqu'à une application complète
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Optimiser').color],
              colorClasses[getSkill('Optimiser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Optimiser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Séléctionner les algorithmes adéquats pour répondre à un problème
            donné
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Administrer').color],
              colorClasses[getSkill('Administrer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Administrer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Déployer des services dans une architecture réseau
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Gérer').color],
              colorClasses[getSkill('Gérer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Gérer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Optimiser une base de données, intéragir avec une application et
            mettre en oeuvre la sécurité
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Conduire').color],
              colorClasses[getSkill('Conduire').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Conduire").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Appliquer une démarche de suivi de projet en fonction des besoins
            métiers des clients et des utilisateurs
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Collaborer').color],
              colorClasses[getSkill('Collaborer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Collaborer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Situer son rôle et ses mission au sein d'une équipe informatique
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Stage').color],
              colorClasses[getSkill('Stage').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Stage").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Compétence liée aux mission faite lors du stage
          </span>
        </div>
        <!--FIN BUT2-->

        <div
          class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-700 dark:after:border-neutral-700"
        >
          Compétences de la troisième année de BUT
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('BUT3').color],
              colorClasses[getSkill('BUT3').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("BUT3").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Troisième année de BUT Informatique
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Alternance').color],
              colorClasses[getSkill('Alternance').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Alternance").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Compétence liée aux projets fait lors de l'alternance
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Réaliser').color],
              colorClasses[getSkill('Réaliser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Réaliser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Adapter des applications sur un ensemble de supports
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Optimiser').color],
              colorClasses[getSkill('Optimiser').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Optimiser").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Analyser et optimiser des applications
          </span>
        </div>
        <div class="mb-2 flex gap-x-1 gap-y-1 flex-wrap">
          <span
            :class="[
              backgroundClasses[getSkill('Collaborer').color],
              colorClasses[getSkill('Collaborer').color],
            ]"
            class="w-fit inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium dark:bg-blue-800/30 dark:text-blue-500"
            >{{ getSkill("Collaborer").title }}</span
          >
          <span class="text-sm font-medium text-left content-center"
            >Manager une équipe informatique
          </span>
        </div>
      </div>

      <!-- Education -->
    </div>
  </main>
  <!-- ========== END MAIN CONTENT ========== -->

  <!-- ========== FOOTER ========== -->
  <footer class="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="py-6 border-t border-gray-200 dark:border-neutral-700">
      <div class="flex flex-wrap justify-between items-center gap-2">
        <div>
          <p class="text-xs text-gray-600 dark:text-neutral-400">
            Naël Benhiba 2024.
          </p>
        </div>
        <!-- End Col -->

        <!-- List -->
        <ul class="flex flex-wrap items-center">
          <li
            class="inline-block relative pe-4 text-xs last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-1.5 before:-translate-y-1/2 before:size-[3px] before:rounded-full before:bg-gray-400 dark:text-neutral-500 dark:before:bg-neutral-600"
          >
            <a
              class="text-xs text-gray-500 underline hover:text-gray-800 hover:decoration-2 focus:outline-none focus:decoration-2 dark:text-neutral-500 dark:hover:text-neutral-400"
              href="https://www.linkedin.com/in/na%C3%ABl-benhiba-7a920a222/"
            >
              Linkedin
            </a>
          </li>
        </ul>
        <!-- End List -->
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Project, Skill } from "@/types/types";
import { Linkedin } from "lucide-vue-next";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    Linkedin,
  },
})
export default class HomePage extends Vue {
  colorClasses = {
    orange: "text-orange-800",
    green: "text-green-800",
    pink: "text-pink-800",
    purple: "text-purple-800",
    "gray-light": "text-gray-800",
    rose: "text-rose-800",
    blue: "text-blue-800",
    yellow: "text-yellow-800",
    red: "text-red-800",
    lime: "text-lime-800",
    fuchsia: "text-fuchsia-800",
  };

  backgroundClasses = {
    orange: "bg-orange-100",
    green: "bg-green-100",
    pink: "bg-pink-100",
    purple: "bg-purple-100",
    "gray-light": "bg-gray-100",
    rose: "bg-rose-100",
    blue: "bg-blue-100",
    yellow: "bg-yellow-100",
    red: "bg-red-100",
    lime: "bg-lime-100",
    fuchsia: "bg-fuchsia-100",
  };

  revColorClasses = {
    orange: "text-orange-100",
    green: "text-green-100",
    pink: "text-pink-100",
    purple: "text-purple-100",
    "gray-light": "text-gray-100",
    rose: "text-rose-100",
    blue: "text-blue-100",
    yellow: "text-yellow-100",
    lime: "text-lime-100",
    fuchsia: "text-fuchsia-100",
    red: "text-red-100",
  };

  revBackgroundClasses = {
    orange: "bg-orange-800",
    green: "bg-green-800",
    pink: "bg-pink-800",
    purple: "bg-purple-800",
    "gray-light": "bg-gray-800",
    rose: "bg-rose-800",
    blue: "bg-blue-800",
    yellow: "bg-yellow-800",
    lime: "bg-lime-800",
    fuchsia: "bg-fuchsia-800",
    red: "bg-red-800",
  };
  selectedSkills: Skill[] = [];

  mounted(): void {
    this.selectedSkills = this.getSkills();
    console.log(this.selectedSkills);
  }

  getFilteredProjects(): Project[] {
    return this.getProjects().filter((project) =>
      project.skills.some((skill) => this.isSkillSelected(skill))
    );
  }

  getProjects(): Project[] {
    return [
      {
        title: "Création d'un site web vitirine",
        desc: "Création d'un site web vitrine pour présenter le BUT Informatique",
        skills: [this.getSkill("BUT1"), this.getSkill("Réaliser")],
      },
      {
        title: "Création d'un site d'achat en ligne",
        desc: "Création d'une boutique en ligne en HTML, CSS, Javascript et PHP ",
        skills: [
          this.getSkill("BUT2"),
          this.getSkill("Réaliser"),
          this.getSkill("Collaborer"),
          this.getSkill("Conduire"),
        ],
      },
      {
        title: "Création d'une application dans le but de visualiser un Graphe",
        desc: "Création d'une application afin de visualiser et analyser un Graphe en Java ",
        skills: [
          this.getSkill("BUT1"),
          this.getSkill("Réaliser"),
          this.getSkill("Optimiser"),
          this.getSkill("Collaborer"),
        ],
      },
      {
        title: "Création d'un annuaire en C",
        desc: "Création d'un programme permettant de gérer, trier, rechercher et enregistrer un annuaire en C depuis un fichier au format CSV",
        skills: [
          this.getSkill("BUT1"),
          this.getSkill("Réaliser"),
          this.getSkill("Optimiser"),
        ],
      },
      {
        title: "Création d'une base de données SQL",
        desc: "Création d'une base de données ",
        skills: [
          this.getSkill("BUT1"),
          this.getSkill("Gérer"),
          this.getSkill("Administrer"),
        ],
      },
      {
        title: "Course Manager",
        desc: "Création d'une application en Flutter permettant de gérer les courses au sein d'un même foyer ",
        skills: [this.getSkill("Projet personnel")],
      },
      {
        title: "Mission du stage",
        desc: 'début de "l\'App", un site web en Vue.js pour permettre aux clients de Gonexa de visualiser plus facilement leurs informations et configurer les produits facilement',
        skills: [
          this.getSkill("Stage"),
          this.getSkill("BUT2"),

          this.getSkill("Réaliser"),
          this.getSkill("Optimiser"),
          this.getSkill("Collaborer"),
        ],
      },
      {
        title: "Projet tuteuré - première partie",
        desc: 'Suite et mise en production de "l\'App", un site web en Vue.js pour permettre aux clients de Gonexa de visualiser plus facilement leur informations et configurer les produits facilement\nTechnologies utilisées : Vue.Js et C#',
        skills: [
          this.getSkill("Alternance"),
          this.getSkill("BUT3"),
          this.getSkill("Réaliser"),
          this.getSkill("Optimiser"),
        ],
      },
      {
        title: "Projet tuteuré - deuxième partie",
        desc: "Création du Webdoc, un nouveau produit Gonexa qui permet d'envoyer pour signature éléctronique non cértifée, des documents stockés sur Salesforce.\nTechnologies utilisées : Vue.Js, Apex et C#",
        skills: [
          this.getSkill("Alternance"),
          this.getSkill("BUT3"),
          this.getSkill("Réaliser"),
          this.getSkill("Optimiser"),
        ],
      },
    ];
  }

  getSkills(): Skill[] {
    return [
      { title: "BUT1", color: "blue" },
      { title: "BUT2", color: "orange" },
      { title: "Réaliser", color: "green" },
      { title: "Optimiser", color: "pink" },
      { title: "Administrer", color: "purple" },
      { title: "Gérer", color: "orange" },
      { title: "Conduire", color: "gray-light" },
      { title: "Collaborer", color: "yellow" },
      { title: "Projet personnel", color: "yellow" },
      { title: "BUT3", color: "red" },
      { title: "Alternance", color: "fuchsia" },
      { title: "Stage", color: "lime" },
    ];
  }

  getSkill(title: string) {
    const s = this.getSkills().findIndex((elem) => {
      if (elem.title == title) return elem;
    });

    return this.getSkills()[s];
  }

  isSkillSelected(skill: Skill) {
    let isSelected = false;
    this.selectedSkills.forEach((elem) => {
      if (elem.title == skill.title) isSelected = true;
    });
    return isSelected;
  }

  getColor(skill: Skill) {
    return `text-${skill.color}-800`;
  }
  getBg(skill: Skill) {
    const color = `bg-${skill.color}-100`;
    return color;
  }
  toggleSkill(skill: Skill) {
    if (this.isSkillSelected(skill)) {
      const index = this.selectedSkills.findIndex((elem) => {
        if (elem.title == skill.title) return elem;
      });
      this.selectedSkills.splice(index, 1);
    } else {
      this.selectedSkills.push(skill);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
